require("./bootstrap");

import Alpine from "alpinejs";
import Focus from "@alpinejs/focus";

Alpine.plugin(Focus);

window.Alpine = Alpine;

Alpine.start();

NProgress.configure({ ease: "ease", speed: 500 }); // Adjust animation settings using easing (a CSS easing string) and speed (in ms). (default: ease and 200)
NProgress.configure({ trickleSpeed: 800 }); //Adjust how often to trickle/increment, in ms.
NProgress.configure({ showSpinner: false }); //Turn off loading spinner by setting it to false. (default: true)
//NProgress.configure({ parent: '#container' });//specify this to change the parent container. (default: body)

NProgress.start(); // start
NProgress.set(0.4); // To set a progress percentage, call .set(n), where n is a number between 0..1
NProgress.inc(); // To increment the progress bar, just use .inc(). This increments it with a random amount. This will never get to 100%: use it for every image load (or similar).If you want to increment by a specific value, you can pass that as a parameter
NProgress.done(); // end

//flatpickr.localize(Russian);
flatpickr.localize(flatpickr.l10ns.fr);
flatpickr("#flatPickr", {
    dateFormat: "Y-m-d H:i:ss",
    altInput: true,
    altFormat: "d.m.Y H:i:ss",
    enableTime: true,
    minTime: "12:00",
    maxTime: "12:00",
    //plugins: confirmDatePlugin({})
});

/* document.addEventListener('DOMContentLoaded', function(){ */
window.onload = function () {
    document.getElementById("menu-hamburger").onclick = function () {
        document.getElementById("nav-content").classList.toggle("hidden");
        //document.getElementById("nav-content").classList.toggle("ease");
    };

    document.getElementById("menu-cross").onclick = function () {
        this.classList.toggle("pointer-events-none");
        document.getElementById("nav-content").classList.toggle("hidden");
        document.getElementById("nav-content").classList.toggle("ease");
    };
};

jQuery(function () {
    $("#closeAlert").on("click", function () {
        $("#alert").addClass("hidden");
    });

    // Deactivate other option of each select ()
    function deactivateSelected(className) {
        const $selects = $(className);

        const selectedValue = [];

        $selects
            .find(":selected")
            .filter(function (idx, el) {
                return $(el).attr("value");
            })
            .each(function (idx, el) {
                selectedValue.push($(el).attr("value"));
            });

        $selects.find("option").each(function (idx, option) {
            if (selectedValue.indexOf($(option).attr("value")) > -1) {
                if ($(option).is(":checked")) {
                    return;
                } else {
                    $(this).attr("disabled", true);
                }
            } else {
                $(this).attr("disabled", false);
            }
        });
    }

    deactivateSelected(".selectNumber");

    $(".selectNumber").on("change", function () {
        deactivateSelected(".selectNumber");
    });

    deactivateSelected(".selectNumberChoosed");

    $(".selectNumberChoosed").on("change", function () {
        deactivateSelected(".selectNumberChoosed");
        //console.log('Nombre choisie');
    });

    function hideAlert() {
        $("#alert").fadeToggle("slow", "linear", function () {
            $("#alert").addClass("hidden");
        });
    }

    $("#alert").show(1000, function () {
        setTimeout(hideAlert, 20000);
    });
});

/** Modal Tailwind CSS **/
var openmodal = document.querySelectorAll(".modal-open");
for (var i = 0; i < openmodal.length; i++) {
    openmodal[i].addEventListener("click", function (event) {
        event.preventDefault();
        toggleModal();
    });
}

//Pour fermer le modal lorsqu'on clique en dehors du modal
/* const overlay = document.querySelector('.modal-overlay')
overlay.addEventListener('click', toggleModal) */

var closemodal = document.querySelectorAll(".modal-close");
for (var i = 0; i < closemodal.length; i++) {
    closemodal[i].addEventListener("click", toggleModal);
}

document.onkeydown = function (evt) {
    evt = evt || window.event;
    var isEscape = false;

    // Pour utiliser le bouton ESC afin de fermer le modal
    /* if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc")
    }
    else {
        isEscape = (evt.keyCode === 27)
    } */
    if (isEscape && document.body.classList.contains("modal-active")) {
        toggleModal();
    }
};

function toggleModal() {
    const body = document.querySelector("body");
    const modal = document.querySelector(".modal");
    modal.classList.toggle("opacity-0");
    modal.classList.toggle("pointer-events-none");
    body.classList.toggle("modal-active");
}
/** FIN Modal Tailwind CSS **/

/* (function (win, doc) {
    "use strict";
    console.clear();

    const $target = doc.querySelector('[data-js-modal="target"]');
    const $open = doc.querySelectorAll('[data-js-modal="open"]');
    const $close = doc.querySelectorAll('[data-js-modal="close"]');
    const toggleClassName = "hidden";

    on($open, "click", modalOpen);
    on($close, "click", modalClose);
    win.addEventListener("keydown", (event) => {
        if (
            !$target.classList.contains(toggleClassName) &&
            event.keyCode === 27
        )
            modalClose();
    });

    function on($element, eventName, fallback) {
        Array.from($element).forEach(($item) => {
            $item.addEventListener(eventName, fallback, false);
        });
    }

    function modalOpen() {
        $target.classList.remove(toggleClassName);
    }

    function modalClose() {
        $target.classList.add(toggleClassName);
    }
})(window, document); */
